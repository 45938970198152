import styled from 'styled-components';
import ColoredBox from '../../components/ColoredBox';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px 10px;

  strong {
    text-align: center;
    font-size: 18px;
    color: #0072BB;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  div.values-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 10px 0 25px;
  }

  > article {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      line-height: 18px;
    }
  }

  > small {
      font-style: italic;
      font-size: 11px;
      color: #666360;
      text-align: center;
      width: 75%;
    }

  > small.comment {
    padding-top: 15px;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const InfoValuesBox = styled(ColoredBox)`
  width: 230px;
  height: 115px;

  padding: 10px;
  position: relative;

  span {
    font-weight: bold;
    font-size: 14px;
  }

  h3 {
    font-size: 18px;
    margin-top: 10px;
  }

  small {
    display: inline-block;
    font-size: 11px;
    line-height: 13px;
    padding: 3px 0;
    bottom: 0;
    margin-top: 8px;

    & + small {
      margin-top: 0;
    }

  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 180px;
    height: 130px;
    padding: 8px;

    span {
      font-size: 14px;
    }

    h3 {
      font-size: 16px;
    }
  }
`

export const GraphWrapper = styled.div`
  height: 300px;
  width:100%;
  margin: 20px 0px 10px;
  font-size: 10px;

  div.tooltip {
    width: 160px;
    height: 100%;
    padding: 5px 10px;

    background-color: #FFF;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 4px 6px rgba(101, 101, 101,0.2);
    border-radius: 4px;

    font-size: 11px;

    h4, p {
      font-weight: normal;
      margin-bottom: 5px;
    }

    span {
      font-weight: bold;
    }
  }
`

export const TextContainer = styled.div`
  margin-bottom: 20px;

  > h3 {
    text-align: center;
    margin-bottom: 15px;
  }
  > p {
    text-align: justify;
    font-size: 12px;
    margin-bottom: 8px;
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`
