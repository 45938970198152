import styled, { css } from 'styled-components';
import { transparentize } from 'polished'
import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
  sizeBox?: 'small' | 'large'
}

export const Container = styled.div<ContainerProps>`
  color: ${props => props.theme.colors.placeholder};
  border: 1px solid ${ transparentize( 0.4, '#AEAEAE')};
  background: transparent;
  border-radius: 4px;

  display: flex;
  height: 70px;
  width: 100%;
  padding: 16px 12px 20px;
  margin-bottom: 12px;

  align-items: center;
  position: relative;
  transition: .2s;

  > div {
    flex: 1;
    margin-top: 5px;
    width: 100%;
  }


  ${({ sizeBox }) => (sizeBox && sizeBox === 'large') && css`
    height: 90px;
    > label {
      font-size: 14px;
    }`}


  ${props =>
    props.isErrored &&
    css`
      border-color: ${props => props.theme.colors.error};
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: ${props => props.theme.colors.mainColor};
      color: ${props => props.theme.colors.mainColor};
    `}


  ${props =>
    props.isFilled &&
    css`
      color: ${props => props.theme.colors.text};
    `}

  > label {
    position: absolute;
    top: 16px;
    left: 12px;
    padding-right: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.placeholder};
    transition: .2s;
    pointer-events: none;
  }
  ${props =>
    props.isErrored && (props.sizeBox && props.sizeBox === 'large') &&
    css`
      > label {
        font-size:13px;
        padding-right:30px;
      }
    `}

${
  props => (props.isFilled || props.isFocused) && css`
    padding-top:24px;
    ${props.sizeBox === 'large' && css`
      padding-top:33px;
    `}
    > label {
      transform: translateY(-12px);
      font-size: 12px;
      letter-spacing: 0.1em;
    }
  `}



span {
  color: ${ props => props.theme.colors.text};
  margin-right: 4px;
  display: inline-block;
}

input {
  appearance: none;
  font-size: 50px;
  color: ${ props => props.theme.colors.text};
  flex: 1;
  border: 0;
  background: transparent;

    &::placeholder {
    color: ${ props => props.theme.colors.placeholder};
  }
}
svg {
  margin-right: 12px;
}

`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 12px;
  max-width: 20px;
  text-align: center;

  svg {
    margin: 0%;
  }

  span {
    background: ${ props => props.theme.colors.error};
    color: #fff;

      &::before {
      border-color: ${ props => props.theme.colors.error} transparent;
    }
  }
`
