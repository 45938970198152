/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { FiDollarSign, FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import Collapse from '@material-ui/core/Collapse'

import usePersistedState from '../../hooks/usePersistedState'
import { formatValue } from '../../utils/formatValues'

import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  Container,
  Content,
  BtnVoltar,
  ButtonController,
  URContent,
  URBox,
  InfoContent,
  Line,
  NoContribCheck,
} from './styles'
import { UserData, UserDetails } from '../../utils/interfaces'
import Input from '../../components/Input'
import getValidationErrors from '../../utils/getValidationErrors'

const ContributionValues: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [vlrCtbBasica, setVlrCtbBasica] = useState(
    userDetails.contribuicaoBasica > 0 ? userDetails.contribuicaoBasica : 0,
  )
  const [vlrCtbPatrocinadora, setVlrCtbPatrocinadora] = useState(
    userDetails.contribuicaoPatrocinadora > 0
      ? userDetails.contribuicaoPatrocinadora
      : 0,
  )
  const [vlrCtbSupPatrocinadora, setVlrCtbSupPatrocinadora] = useState(
    userDetails.contribuicaoPatrocinadora > 0
      ? userDetails.contribuicaoPatrocinadora
      : 0,
  )
  const [vlrCtbTotal, setVlrCtbTotal] = useState(
    userDetails.contribuicaoBasica * 2 > 0
      ? userDetails.contribuicaoBasica * 2
      : 0,
  )
  const [pctCtbBas, setPctCtbBas] = useState(
    userDetails.pctContribuicaoBasica > 0
      ? userDetails.pctContribuicaoBasica
      : 5,
  )

  const [pctCtbSup, setPctCtbSup] = useState(
    userDetails.pctContribuicaoSuplementar > 0
      ? userDetails.pctContribuicaoSuplementar
      : 0,
  )
  const [vlrCtbSup, setVlrCtbSup] = useState(
    userDetails.contribuicaoSuplementar > 0
      ? userDetails.contribuicaoSuplementar
      : 0,
  )

  const [ur, setUr] = useState(0)
  const [visibility, setVisibility] = useState(false)
  const [salarioValue, setSalarioValue] = useState(
    userDetails.salario > 0 ? userDetails.salario : 0,
  )

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  useEffect(() => {
      // OLD VALUES
      // UR - RBI 525.7688
      // UR - RBC 556.9175
      // UR - RBH 547.7088
      // UR - MJN 525.7688

    if (userData.patrocinadora.toString() === '71' || userData.patrocinadora.toString() === '100') {
      // UR - RBI && ABN
      setUr(610.339)
    }
    else if (userData.patrocinadora.toString() === '74') {
      // UR - RBC
      setUr(606.09)
    }
    else if (userData.patrocinadora.toString() === '75') {
      // UR - RBH
      setUr(612.9951)
    }
    else if (userData.patrocinadora.toString() === '76') {
      // UR - MJN
      setUr(610.339)
    }

    setVlrCtbBasica(0)
    setVlrCtbPatrocinadora(0)
    setVlrCtbTotal(0)
  }, [userData.patrocinadora])

  /* CALCULO DE FAIXAS DE CONTRIBUIÇÃO */
  useEffect(() => {
    const vlrResidual = salarioValue < ur * 8 ? 0 : salarioValue - ur * 8
    const vlrContrib =
      salarioValue < ur * 8
        ? salarioValue * 0.005
        : vlrResidual * (pctCtbBas / 100)

    setVlrCtbBasica(vlrContrib)
    setVlrCtbPatrocinadora(vlrContrib)
    setVlrCtbSupPatrocinadora(vlrContrib)
    setVlrCtbTotal(vlrCtbBasica + vlrCtbPatrocinadora + vlrCtbSupPatrocinadora)
  }, [
    salarioValue,
    pctCtbBas,
    ur,
    vlrCtbBasica,
    vlrCtbPatrocinadora,
    vlrCtbSupPatrocinadora,
  ])

  useEffect(() => {
    if (salarioValue < ur * 8) {
      setPctCtbBas(0.5)
    } else {
      setPctCtbBas(5)
    }
  }, [salarioValue, ur])

  const minusContrib = useCallback(() => {
    if (pctCtbBas <= 1) {
      setPctCtbBas(1)
    } else {
      const value = pctCtbBas > 5 ? 5 : pctCtbBas - 1
      setPctCtbBas(value)
    }
  }, [pctCtbBas])
  const plusContrib = useCallback(() => {
    if (pctCtbBas >= 5) {
      setPctCtbBas(5)
    } else {
      const value = pctCtbBas < 1 ? 1 : pctCtbBas + 1
      setPctCtbBas(value)
    }
  }, [pctCtbBas])

  /* CALCULO DE FAIXAS DE CONTRIBUIÇÃO SUPLEMENTAR */
  useEffect(() => {
    const value = salarioValue * (pctCtbSup / 100)

    setVlrCtbSup(value)
  }, [pctCtbSup, salarioValue])
  const minusContribSup = useCallback(() => {
    if (pctCtbSup <= 0) {
      setPctCtbSup(0)
    } else {
      setPctCtbSup(pctCtbSup - 1)
    }
  }, [pctCtbSup])
  const plusContribSup = useCallback(() => {
    if (pctCtbSup >= 100) {
      setPctCtbSup(100)
    } else {
      setPctCtbSup(pctCtbSup + 1)
    }
  }, [pctCtbSup])

  const mudarSalario = useCallback(valor => {
    const v = valor.replace(',', '').replaceAll('.', '')
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(v) || v === '' || v === undefined) {
      setSalarioValue(0)
    } else {
      const m = Math.floor(v.length - 2)
      const a = `${v.substr(0, m)}.${v.substr(m)}`
      const f = parseFloat(a)
      setSalarioValue(f)
    }
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          salario: Yup.string()
            .test('', 'Salário inválido.', () => salarioValue <= 100000)
            .required('Campo obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserDetails({
          ...userDetails,
          salario: salarioValue,
          contribuicaoBasica: vlrCtbBasica,
          contribuicaoPatrocinadora: vlrCtbPatrocinadora * 2,
          pctContribuicaoBasica: pctCtbBas,
          contribuicaoSuplementar: vlrCtbSup,
          pctContribuicaoSuplementar: pctCtbSup,
        })

        history.push('/simulation')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      history,
      pctCtbBas,
      pctCtbSup,
      salarioValue,
      setUserDetails,
      userDetails,
      vlrCtbBasica,
      vlrCtbPatrocinadora,
      vlrCtbSup,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={userDetails}>
          <Content>
            <strong>Contribuição básica</strong>
            <p>
              Aqui você irá definir sua contribuição para o seu Plano de
              Previdência. Os percentuais definidos serão descontados
              mensalmente do seu salário. A patrocinadora também contribuirá
              mensalmente para a formação do seu benefício futuro, com o dobro
              da sua contribuição básica.
            </p>
            <Input
              icon={FiDollarSign}
              name="salario"
              mask="currency"
              type="text"
              placeholder="Qual o seu salário base R$?"
              onChange={e => mudarSalario(e.target.value)}
            />

            <Collapse in={salarioValue !== 0} className="collapse">
              <URContent>
                {salarioValue < ur * 8 ? (
                  <URBox>
                    <div className="UR-info">
                      <p>até 8 URCs*</p>
                    </div>
                    <div>
                      <ButtonController type="button" disabled>
                        <FiMinusCircle />
                      </ButtonController>
                      <strong>{pctCtbBas}%</strong>
                      <ButtonController type="button" disabled>
                        <FiPlusCircle />
                      </ButtonController>
                    </div>
                    <strong>{formatValue(vlrCtbBasica)}</strong>
                  </URBox>
                ) : (
                  <URBox>
                    <div className="UR-info">
                      <p>acima de 8 URCs*</p>
                    </div>
                    <div>
                      <ButtonController
                        type="button"
                        disabled={pctCtbBas <= 1}
                        isSelected={pctCtbBas > 1}
                        onClick={() => minusContrib()}
                      >
                        <FiMinusCircle />
                      </ButtonController>
                      <strong>{pctCtbBas}%</strong>
                      <ButtonController
                        type="button"
                        disabled={pctCtbBas >= 5}
                        isSelected={pctCtbBas < 5}
                        onClick={() => plusContrib()}
                      >
                        <FiPlusCircle />
                      </ButtonController>
                    </div>
                    <strong>{formatValue(vlrCtbBasica)}</strong>
                  </URBox>
                )}
              </URContent>
            </Collapse>

            <Line />

            <InfoContent>
              <div>
                <p>Valor da contribuição básica do Participante: </p>
                <strong>{formatValue(vlrCtbBasica)}</strong>
              </div>
              <div>
                <p>Valor da contribuição normal da Patrocinadora: </p>
                <strong style={{ color: '#333', fontSize: '14px' }}>
                  {formatValue(vlrCtbPatrocinadora)}
                </strong>
              </div>
              <div>
                <p>Valor da contribuição suplementar da Patrocinadora: </p>
                <strong style={{ color: '#333', fontSize: '14px' }}>
                  {formatValue(vlrCtbSupPatrocinadora)}
                </strong>
              </div>
              <div>
                <div>
                  <p>Valor total de contribuição ao Plano: </p>
                  <small>(participante + patrocinadora)</small>
                </div>
                <strong>{formatValue(vlrCtbTotal)}</strong>
              </div>
              <span>Valor URC: {formatValue(ur)}</span>
            </InfoContent>
          </Content>

          <Collapse
            in={
              (salarioValue > ur * 8 && pctCtbBas >= 5) ||
              (salarioValue <= ur * 8 && salarioValue > 0)
            }
          >
            <Content>
              <strong>Contribuição adicional</strong>
              <p>
                Para contribuir ainda mais com a formação da sua reserva,
                cadastre uma contribuição adicional.
              </p>
              <small>
                Obs.: A patrocinadora não fará o dobro da sua Contribuição
                Adicional.
              </small>
              <Line />

              <Collapse in={!visibility} className="collapse">
                <div className="contrib-sup">
                  <p>
                    Com qual % sobre o seu salário você deseja contribuir
                    adicionalmente?
                  </p>
                  <div>
                    <ButtonController
                      type="button"
                      disabled={pctCtbSup <= 0}
                      isSelected={pctCtbSup > 0}
                      onClick={() => minusContribSup()}
                    >
                      <FiMinusCircle />
                    </ButtonController>
                    <strong>{pctCtbSup}%</strong>
                    <ButtonController
                      type="button"
                      isSelected={pctCtbSup < 100}
                      onClick={() => plusContribSup()}
                    >
                      <FiPlusCircle />
                    </ButtonController>
                  </div>
                </div>
                <div className="value">
                  <p>Valor da contribuição adicional:</p>
                  <strong>{formatValue(vlrCtbSup)}</strong>
                </div>
              </Collapse>

              <NoContribCheck>
                <div>
                  <input
                    name="checkbox"
                    type="checkbox"
                    checked={visibility}
                    onChange={e => {
                      setVisibility(e.target.checked)
                      setPctCtbSup(0)
                      setVlrCtbSup(0)
                    }}
                  />
                  <small onClick={() => setVisibility(!visibility)}>
                    Não quero cadastrar uma contribuição adicional.
                  </small>
                </div>
              </NoContribCheck>

              <div className="rules">
                <small>
                  Está em dúvida? Não se preocupe, depois você poderá alterar
                  este valor sempre que desejar.
                </small>
              </div>
            </Content>
          </Collapse>
        </Form>

        <Button
          type="submit"
          color="pink"
          onClick={handleClick}
          disabled={
            salarioValue < 1 ||
            (pctCtbBas === 5 && vlrCtbSup === 0 && !visibility) ||
            (pctCtbBas === 0.5 && vlrCtbSup === 0 && !visibility)
          }
        >
          Próximo
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default ContributionValues
