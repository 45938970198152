import React, { useEffect } from 'react'

import endImage from '../../assets/end-image.svg'

import Header from '../../components/Header'
import { Container, Content } from './styles'

const End: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem('@ReckittPrev:erroProps')
    localStorage.removeItem('@ReckittPrev:faixa1')
    localStorage.removeItem('@ReckittPrev:faixa2')
    localStorage.removeItem('@ReckittPrev:participantsGroup')
    localStorage.removeItem('@ReckittPrev:PercentualValuePercent')
    localStorage.removeItem('@ReckittPrev:PercentualValueSaque')
    localStorage.removeItem('@ReckittPrev:receiveTypeSelected')
    localStorage.removeItem('@ReckittPrev:TimeValueYears')
    localStorage.removeItem('@ReckittPrev:totalBalance')
    localStorage.removeItem('@ReckittPrev:acceptTerms')
    localStorage.removeItem('@ReckittPrev:userData')
    localStorage.removeItem('@ReckittPrev:userDetails')
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Pronto! Seu pedido de aquisição do Plano de Benefícios II foi salvo
            com sucesso!
          </strong>

          <img src={endImage} alt="endImage" />

          <p>
            O próximo passo é entrar no e-mail e acessar o link do
            autoatendimento para completar seu cadastro.
          </p>

          <div>
            <strong>ACESSE O NOSSO SITE:</strong>
            <a href="http://reckittprev.com.br/">www.reckittprev.com.br</a>
          </div>
        </Content>
      </Container>
    </>
  )
}

export default End
