import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FiArrowRightCircle } from 'react-icons/fi'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { Container, Content } from './styles'

const BemVindo: React.FC = () => {
  const history = useHistory()

  const handleStart = useCallback(async () => {
    history.push('/contribution')
  }, [history])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Olá, você é empregado da Reckitt?</strong>
          <span>
            Sim? Então comece agora mesmo a planejar o seu futuro fazendo sua
            adesão ao Plano de Benefícios II.
          </span>
          <Button color="pink" onClick={handleStart} width="large">
            Simule aqui sua adesão!
            <FiArrowRightCircle size={45} />
          </Button>
          <small>
            O Plano de Benefícios II é um plano de previdência oferecido aos
            empregados que não possuem vinculação a outro plano de benefícios
            administrado pela ReckittPrev ou a outro plano de benefícios
            patrocinado pelas patrocinadoras.
          </small>
          <small>
            Como participante do Plano de Benefícios II, você contribuirá com
            uma parte (Contribuição Básica) e a empresa com duas vezes
            (Contribuição Normal e Suplementar).
          </small>
          <small>
            Incentivo Fiscal: Você pode deduzir suas contribuições à ReckittPrev
            até o limite de 12% de sua renda bruta anual. Para ter esse direito,
            você deve utilizar o modelo completo da declaração de ajuste anual e
            ser contribuinte do INSS. Desta forma você também irá abater
            mensalmente o cálculo base do imposto de renda do seu salário.
          </small>
        </Content>
      </Container>
    </>
  )
}

export default BemVindo
