/* eslint-disable eqeqeq */
import React, { useRef, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import moment from 'moment'
import * as Yup from 'yup'

import { FiClock } from 'react-icons/fi'
import { BiCake } from 'react-icons/bi'

import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import calculaIdade from '../../utils/calculaIdade'

import Input from '../../components/Input'
import Button from '../../components/Button'
import Header from '../../components/Header'

import { UserData, UserDetails } from '../../utils/interfaces'
import { Container, Content, InfoContent, Line, BtnVoltar } from './styles'
import InputSelect from '../../components/InputSelect'

const Contribution: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )

  const [idadeApos, setIdadeApos] = useState(userDetails.age)
  const [patrocinadora, setPatrocinadora] = useState({
    label: userData.dcrPatrocinadora,
    value: userData.patrocinadora,
  })

  const history = useHistory()

  const formRef = useRef<FormHandles>(null)

  const handleChangePatrocinadora = useCallback(e => {
    const t = e
    setPatrocinadora(t)
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        const now = new Date()
        const bday = data.birthdate.split('/').reverse().join('-')
        const ano = bday.split('-')[0]
        const idadeAtual = calculaIdade(bday)
        const anosContrib2 = idadeApos - (now.getFullYear() - parseInt(ano, 10))

        const idadeMinima = anosContrib2 < 5 ? idadeAtual + 5 : 50

        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          birthdate: Yup.string()
            .required('Campo obrigatório')
            .min(10, 'A data de nascimento deve ter o formato dd/mm/aaaa.')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(data.birthdate.split('/').reverse().join('-')) ||
                data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                moment(
                  data.birthdate.split('/').reverse().join('-'),
                ).isValid() || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                calculaIdade(data.birthdate.split('/').reverse().join('-')) <=
                  115 || data.birthdate === '',
            ),
          age: Yup.number()
            .typeError('Campo obrigatório')
            .required(
              'Digite a idade desejada para a aposentadoria para realizar a simulação',
            )
            .min(
              idadeMinima < 50 ? 50 : idadeMinima,
              `Idade inválida. Por favor, escolha uma idade igual ou superior a ${
                idadeMinima < 50 ? 50 : idadeMinima
              } anos`,
            )
            .max(115, 'Idade inválida.'),
          patrocinadora: Yup.string().required('Patrocinadora é obrigatória'),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserDetails({
          ...userDetails,
          years: anosContrib2,
          age: idadeApos,
        })

        await setUserData({
          ...userData,
          birthdate: bday,
          patrocinadora: patrocinadora.value,
          dcrPatrocinadora: patrocinadora.label,
        })

        // localStorage.removeItem('@ReckittPrev:PercentualValueSaque')
        // localStorage.removeItem('@ReckittPrev:PercentualValuePercent')
        // localStorage.removeItem('@ReckittPrev:TimeValueYears')
        history.push('/contribution-values')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      history,
      idadeApos,
      patrocinadora,
      setUserData,
      setUserDetails,
      userData,
      userDetails,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            birthdate:
              userData.birthdate === undefined
                ? ''
                : userData.birthdate.split('-').reverse().join('/'),
            age: userDetails.age,
            patrocinadora: userData.patrocinadora,
          }}
        >
          <Content>
            <strong>Contribuição básica</strong>
            <p>
              Aqui você irá definir sua contribuição para o seu Plano de
              Previdência. Os percentuais definidos serão descontados
              mensalmente do seu salário. A patrocinadora também contribuirá
              mensalmente para a formação do seu benefício futuro, com o dobro
              da sua contribuição básica (faça sua simulação na sequência).
            </p>
            <Input
              icon={BiCake}
              name="birthdate"
              mask="date"
              maxLength={10}
              placeholder="Data de nascimento"
            />
            <Input
              icon={FiClock}
              type="number"
              name="age"
              value={idadeApos}
              sizeBox="large"
              min={50}
              sufix="anos"
              placeholder="Qual a idade desejada para a aposentadoria?"
              onChange={e => setIdadeApos(parseInt(e.target.value, 10))}
            />
            <InputSelect
              name="patrocinadora"
              value={patrocinadora}
              options={[
                { label: 'Reckitt Benckiser (Brasil) Ltda RBI', value: 71 },
                { label: 'Reckitt Benckiser (Brasil) Ltda RBC', value: 74 },
                { label: 'Reckitt Benckiser (Brasil) Ltda HEALTH', value: 75 },
                {
                  label:
                    'Mead Johnson do Brasil Comercio e Importação de Produtos de Nutrição Ltda.',
                  value: 76,
                },
                { label: 'Apenas Boa Nutrição', value: 100 },
              ]}
              onChange={e => handleChangePatrocinadora(e)}
              placeholder="Patrocinadora"
            />

            <Line />
            <InfoContent>
              <small>
                O Salário Base é o total das parcelas remuneratórias pagas pela
                Patrocinadora ao Participante, sobre as quais incidir desconto
                para a Previdência Oficial.
              </small>
              <p>Não integrarão o Salário Base:</p>
              <ul>
                <li>Valores recebidos a título de indenização;</li>
                <li>
                  Verbas indenizatórias decorrentes de rescisão contratual;
                </li>
                <li>Abono de férias na forma da legislação vigente;</li>
                <li>
                  Ganhos eventuais e abonos expressamente desvinculados do
                  salário;
                </li>
                <li>
                  Ajuda de custo recebida exclusivamente em decorrência de
                  mudança de local de trabalho do empregado;
                </li>
                <li>Diárias para viagens.</li>
              </ul>
            </InfoContent>
          </Content>
        </Form>

        <Button type="submit" color="pink" onClick={handleClick}>
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/')}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Contribution
