/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Base64 } from 'js-base64'
import Switch from 'react-switch'
import moment from 'moment'
import * as Yup from 'yup'

import { FiCalendar, FiSmartphone, FiMail, FiUser } from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'

import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import validaCPF from '../../utils/validaCPF'
import api from '../../services/api'

import InputHidden from '../../components/InputHidden'
import ModalBox from '../../components/Modal'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'

import {
  Container,
  RadioButton,
  BtnContato,
  BtnVoltar,
  Content,
  Line,
} from './styles'
import { UserData, ConfigData, ErroProps } from '../../utils/interfaces'
import InputSelect from '../../components/InputSelect'

const Register: React.FC = () => {
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [patrocinadora, setPatrocinadora] = useState({
    label: userData.dcrPatrocinadora,
    value: userData.patrocinadora,
  })

  const [warningText, setWarningText] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [tipoContato, setTipoContato] = useState('S')

  const [clicado, setClicado] = useState(false)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const handleChangePatrocinadora = useCallback(e => {
    const t = e
    setPatrocinadora(t)
  }, [])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const handleConfirmar = useCallback(event => {
    event.preventDefault()
    formRef.current?.submitForm()
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(3, 'Digite o nome completo'),
          cpf: Yup.string()
            .required('CPF obrigatório')
            .test(
              '',
              'CPF inválido',
              () =>
                validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                data.cpf === '',
            ),
          admission: Yup.string()
            .required('Data de admissão obrigatória')
            .min(10, 'A data de admissão deve seguir o formato dd/mm/aaaa.')
            .test(
              '',
              'A data de admissão não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(data.admission.split('/').reverse().join('-')) ||
                data.admission === '',
            )
            .test(
              '',
              'Data de admissão inválida',
              () =>
                moment(
                  data.admission.split('/').reverse().join('-'),
                ).isValid() || data.admission === '',
            ),
          email: Yup.string()
            .required('E-mail pessoal é obrigatório')
            .email('Digite um e-mail válido'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido')
            .required('Celular é obrigatório'),
          tipContato: Yup.string().required(),
          patrocinadora: Yup.string().required('Patrocinadora é obrigatória'),
        })

        await schema.validate(data, { abortEarly: false })

        setClicado(true)

        const dataAdmForm = data.admission.split('/').reverse().join('-')

        await setUserData({
          ...userData,
          name: data.name,
          cpf: data.cpf,
          admission: dataAdmForm,
          email: data.email,
          phone: data.phone,
          patrocinadora: patrocinadora.value,
          dcrPatrocinadora: patrocinadora.label,
        })

        const parametros = Base64.encode(
          `{"versao":"${configData.tipo}",
            "plano": "${configData.plano}",
            "cliente":"${configData.codCliente}",
            "cpf":"${data.cpf.replaceAll('.', '').replace('-', '')}",
            "email":"${data.email === undefined ? '' : data.email}",
            "telefone":"${
              data.phone === undefined
                ? ''
                : `55${data.phone
                    .replace('(', '')
                    .replace(') ', '')
                    .replace('-', '')}`
            }",
            "admissao":"${dataAdmForm}",
            "envio":"${tipoContato}",
            "patrocinadora":"${
              patrocinadora.value === undefined ? 0 : patrocinadora.value
            }"}`,
        )

        const parametrosFinal = Base64.encode(parametros)

        await api
          .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            setConfigData({ ...configData, token: res.data.token, tipoContato })
            history.push('/register/confirm-sms')
          })
          .catch(error => {
            if (error.response.data.codigo === 90003) {
              setErroProps({
                title: error.response.data.titulo,
                description: `${error.response.data.descricao} (cod ${error.response.data.codigo})`,
              })
              setWarningText(
                `${error.response.data.descricao} (cod ${error.response.data.codigo})`,
              )
              setIsModalOpen(true)
              setClicado(false)
            } else if (error.response.data.codigo === 90004) {
              setErroProps({
                title: error.response.data.titulo,
                description: `${error.response.data.descricao} (cod ${error.response.data.codigo})`,
              })
              setWarningText(
                `${error.response.data.descricao} (cod ${error.response.data.codigo})`,
              )
              setIsModalOpen(true)
              setClicado(false)
            } else if (error.response.data.codigo === 90005) {
              setErroProps({
                title: error.response.data.titulo,
                description: `${error.response.data.descricao} (cod ${error.response.data.codigo})`,
              })
              setWarningText(
                `${error.response.data.descricao} (cod ${error.response.data.codigo})`,
              )
              setIsModalOpen(true)
              setClicado(false)
            } else if (error.response.data.codigo === 90006) {
              setErroProps({
                title: error.response.data.titulo,
                description: `${error.response.data.descricao} (cod ${error.response.data.codigo})`,
              })
              setWarningText(
                `${error.response.data.descricao} (cod ${error.response.data.codigo})`,
              )
              setIsModalOpen(true)
              setClicado(false)
            } else if (error.message === 'Network Error') {
              setErroProps({
                title: error.message,
                description: 'Falha na conexão como servidor',
              })
              setClicado(false)
              history.push('/erro')
            } else if (
              // error.message === 'Request failed with status code 500'
              error.response.status === 500
            ) {
              setErroProps({
                title: 'Erro interno no servidor',
                description: error.message,
              })
              setClicado(false)
              history.push('/erro')
            } else if (
              error.response.status === 404
              // error.message === 'Request failed with status code 404'
            ) {
              setWarningText(
                'Não localizamos os seus dados na base cadastral. Por favor, confira as se Patrocinadora, CPF e Data de Admissão foram informadas corretamente. Em caso de dúvidas entre em contato com a área de atendimento.',
              )
              setIsModalOpen(true)
              setClicado(false)
            } else if (
              error.response.status === 401
              // error.message === 'Request failed with status code 401'
            ) {
              setWarningText('Você já é participante do plano.')
              setIsModalOpen(true)
              setClicado(false)
            } else {
              setWarningText(
                error.response.data.descricao
                  ? `${error.response.data.descricao} (cod ${error.response.data.codigo})`
                  : 'Ops, algo deu errado. Tente novamente mais tarde.',
              )
              setIsModalOpen(true)
              setClicado(false)
            }
          })
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      configData,
      history,
      setConfigData,
      setErroProps,
      setUserData,
      tipoContato,
      userData,
      patrocinadora,
    ],
  )

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Informe seus dados para prosseguir:</strong>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: userData.name,
              cpf: userData.cpf,
              admission:
                userData.admission === undefined
                  ? ''
                  : userData.admission.split('-').reverse().join('/'),
              phone: userData.phone,
              email: userData.email,
              patrocinadora: userData.patrocinadora,
            }}
          >
            <InputSelect
              name="patrocinadora"
              value={patrocinadora}
              options={[
                { label: 'Reckitt Benckiser (Brasil) Ltda RBI', value: 71 },
                { label: 'Reckitt Benckiser (Brasil) Ltda RBC', value: 74 },
                { label: 'Reckitt Benckiser (Brasil) Ltda HEALTH', value: 75 },
                {
                  label:
                    'Mead Johnson do Brasil Comercio e Importação de Produtos de Nutrição Ltda.',
                  value: 76,
                },
                { label: 'Apenas Boa Nutrição', value: 100 },
              ]}
              onChange={e => handleChangePatrocinadora(e)}
              placeholder="Patrocinadora"
            />
            <Input name="name" placeholder="Nome Completo" icon={FiUser} />
            <Input
              placeholder="CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
            />
            <Input
              icon={FiCalendar}
              name="admission"
              mask="date"
              maxLength={10}
              placeholder="Data de admissão"
            />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              icon={FiMail}
              name="email"
              id="email"
              type="email"
              placeholder="E-mail"
              maxLength={150}
            />
            <RadioButton>
              <label>Enviar código de confirmação por: </label>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'S'}
                  onClick={() => setTipoContato('S')}
                >
                  SMS
                </BtnContato>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'E'}
                  onClick={() => setTipoContato('E')}
                >
                  E-mail
                </BtnContato>
              </div>
            </RadioButton>
            <InputHidden name="tipContato" value={tipoContato} type="hidden" />

            <Line />

            <div className="terms">
              <small>
                Ao continuar navegando no site, você concorda com as condições
                de{' '}
                <a
                  href="http://reckittprev.com.br/sobre-a-reckittprev/"
                  target="_blank"
                >
                  Privacidade
                </a>{' '}
                e{' '}
                <a
                  href="http://reckittprev.com.br/sobre-a-reckittprev/"
                  target="_blank"
                >
                  Termos de uso
                </a>{' '}
                do site Reckittprev.
              </small>
              <Switch
                onChange={toggleAcceptTerms}
                checked={acceptTerms}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={16}
                offColor="#DEE3E1"
                offHandleColor="#fff"
                onHandleColor="#fff"
                onColor="#31D19E"
              />
            </div>
          </Form>
        </Content>

        <Button
          type="submit"
          color="pink"
          onClick={handleConfirmar}
          disabled={!acceptTerms || clicado}
        >
          {clicado ? 'Aguarde...' : 'Confirmar'}
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
        </ModalBox>
      </Container>
    </>
  )
}

export default Register
