/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback } from 'react'
import { FormHandles, Scope } from '@unform/core'
import { useHistory } from 'react-router-dom'
import { Form } from '@unform/web'

import { FiCheck, FiX } from 'react-icons/fi'
import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { Participant } from '../../utils/interfaces'
import { Container, Content, BenefBox, Line, ContentBenef } from './styles'

const ParticipantsList: React.FC = () => {
  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  const handleAddNovo = useCallback(() => {
    history.push('/new-participant')
  }, [history])

  const handleRemove = useCallback(
    id => {
      setParticipants(() =>
        participants.filter((participant, idx) => idx !== id),
      )
    },
    [participants, setParticipants],
  )

  const handleSubmit = useCallback(() => {
    history.push('/fatca')
  }, [history])

  const handleClickSubmit = useCallback(() => formRef.current?.submitForm(), [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <BenefBox>
              {participants.length > 0 ? (
                <h3>Participantes</h3>
              ) : (
                <h3>Nenhum beneficiário informado.</h3>
              )}

              {participants.map((participant, idx) => (
                <ContentBenef key={Math.random()}>
                  <Scope path={`parts[${idx}]`}>
                    <FiX onClick={() => handleRemove(idx)} />
                    <div>
                      <div>
                        <strong>Nome: </strong>
                        <label>{participant.data.name}</label>
                      </div>
                      <div>
                        <strong>Nascimento: </strong>
                        <label>
                          {participant.data.birthdate
                            .toString()
                            .split('-')
                            .reverse()
                            .join('/')}
                        </label>
                      </div>
                      <div>
                        <strong>CPF: </strong>
                        <label>{participant.data.cpf}</label>
                      </div>
                      <div>
                        <strong>Vínculo: </strong>
                        <label>{participant.details.dcrGrauParentesco}</label>
                      </div>
                    </div>
                  </Scope>
                  <Line />
                </ContentBenef>
              ))}
            </BenefBox>
          </Form>
        </Content>

        <Button
          type="button"
          fontSize="normal"
          color="pink"
          width="large"
          onClick={handleClickSubmit}
        >
          <FiCheck size={45} />
          <span>Pronto! Avançar</span>
        </Button>

        <Button
          type="button"
          fontSize="normal"
          color="white"
          onClick={handleAddNovo}
        >
          {participants.length > 0
            ? 'Adicionar mais um beneficiário'
            : 'Adicionar um beneficiário'}
        </Button>
      </Container>
    </>
  )
}

export default ParticipantsList
